import { useState } from "react";

import SectionsNav4 from "../../Components/SectionsNav4/SectionsNav4";

import ProfileDetails from "./ProfileDetails";
import AccountSettings from "./AccountSettings";

import classes from "./Profile.module.css";

const Profile = () => {
  // Context

  // States
  const [navItems, setNavItems] = useState<any[]>([
    {
      title: "Profile",
      isActive: true,
      activeComponent: (
        <div className={classes.body}>
          <ProfileDetails />
        </div>
      ),
      route: "user",
    },
    {
      title: "Account setting",
      isActive: false,
      activeComponent: <AccountSettings />,
      route: "account-setting",
    },
  ]);

  //   Utils
  const activeComponent = navItems.find((data) => data.isActive);

  return (
    <section className={classes.container}>
      <div className={classes.sectionsNavSection}>
        <SectionsNav4 navItems={navItems} setNavItems={setNavItems} isRouting />
      </div>

      <div>{activeComponent.activeComponent}</div>
    </section>
  );
};

export default Profile;
