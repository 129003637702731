import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import Error from "../../../Components/Error/Error";
import { AuthUserContext } from "../../../Context/AuthUserContext";
import { capitalize } from "../../../HelperFunctions/capitalize";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import classes from "../SignIn/SignIn.module.css";
import subClasses from "./VerifyEmail.module.css";
import success from "../../../Assets/Gifs/success.gif";

const VerifyEmail = () => {
  // Context
  const { searchParams, verifyEmail, verifyEmailRequest, resendVerifyEmail } =
    useContext(AuthUserContext);

  // Router
  const signUpToken = searchParams.get("verificationToken");
  const isVerified = searchParams.get("isVerified");

  const navigate = useNavigate();

  useEffect(() => {
    if (signUpToken) verifyEmail(signUpToken as string);

    // eslint-disable-next-line
  }, []);

  return (
    <AuthLayout>
      <section className={classes.container}>
        {isVerified === "false" || !isVerified ? (
          <>
            <div className={subClasses.feedback}>
              {verifyEmailRequest.data && (
                <Error type="success">
                  {capitalize(verifyEmailRequest?.data as string)}
                </Error>
              )}

              {verifyEmailRequest.error && (
                <Error type="error">
                  {capitalize(verifyEmailRequest?.error as string)}
                </Error>
              )}
            </div>
            <div className={classes.header}>
              <img src={success} alt="Success" />
              <h4>Verify your email address</h4>
              <p>
                We sent a verification email to [user's email address]. Please
                check your inbox. Click the verification link in the mail to
                activate your account.
              </p>
            </div>

            <Button
              onClick={() => (window.location.href = "mailto:")}
              loading={verifyEmailRequest.isLoading}
            >
              Open email app
            </Button>
            <p>Didn't receive the email? Check your spam or junk folder</p>
            <p>
              Still can’t find the email?{" "}
              <span onClick={resendVerifyEmail}>Resend verification email</span>
            </p>
          </>
        ) : (
          <div className={classes.innerContainer}>
            <div className={classes.header}>
              <img src={success} alt="Success" />

              <h4>Email verified</h4>
              <p>
                Congratulations on verifying your email! Start your learning
                journey with iṣẹ́ School.
              </p>
            </div>
            <div className={classes.buttonSection}>
              <Button
                onClick={() => {
                  navigate("/onboarding?step=1");
                }}
                loading={verifyEmailRequest.isLoading}
              >
                Start learning
              </Button>
            </div>
          </div>
        )}
      </section>
    </AuthLayout>
  );
};

export default VerifyEmail;
