import classes from "../SignIn/SignIn.module.css";
import { useContext, useEffect, useState } from "react";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import { AuthUserContext } from "../../../Context/AuthUserContext";
import Input from "../../../Components/Input/Input";
import Error from "../../../Components/Error/Error";
import { capitalize } from "../../../HelperFunctions/capitalize";
import Button from "../../../Components/Button/Button";
import PasswordStrength from "../../Components/PasswordStrength/PasswordStrength";

const ResetPassword = () => {
  const {
    resetPasswordObject,
    setResetPasswordObject,
    verifyResetPassword,
    resetPasswordRequest,
    setResetPasswordRequest,
  } = useContext(AuthUserContext);

  // Router
  const currentSearchParams = new URLSearchParams(window.location.search);
  const resetPasswordToken = currentSearchParams.get("passwordResetToken");

  //   States
  const [passwordIsvalid, setPasswordIsValid] = useState(false);

  // Utils
  const changeHandler = (e: any) => {
    setResetPasswordObject((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  //   Effects
  useEffect(() => {
    setResetPasswordRequest((prevState) => {
      return { ...prevState, data: null };
    });

    // eslint-disable-next-line
  }, []);

  return (
    <AuthLayout>
      <section className={classes.container}>
        <div className={classes.feedback}>
          {resetPasswordRequest.data && (
            <Error type="success">
              {capitalize(resetPasswordRequest?.data as string)}
            </Error>
          )}

          {resetPasswordRequest.error && (
            <Error type="error">
              {capitalize(resetPasswordRequest?.error as string)}
            </Error>
          )}
        </div>

        <div className={classes.header}>
          <h4>Reset password</h4>
          <p>
            Enter a new password you’ll remember. We’ll ask for this password
            whenever you want to log in
          </p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input
            label="Enter new password"
            isRequired
            placeholder="Enter Password"
            tip="Passwords must be at least 8 characters "
            type="password"
            name="newPassword"
            value={resetPasswordObject.newPassword}
            onChange={changeHandler}
          />
          {resetPasswordObject?.newPassword && (
            <PasswordStrength
              password={resetPasswordObject?.newPassword}
              setPasswordConditionsValid={setPasswordIsValid}
            />
          )}

          <span>
            <Input
              label="Confirm new password"
              isRequired
              type="password"
              placeholder="Enter Password"
              errorMessage="Passwords do not match"
              condition={
                resetPasswordObject.newPassword ===
                resetPasswordObject.confirmNewPassword
              }
              name="confirmNewPassword"
              value={resetPasswordObject.confirmNewPassword}
              onChange={changeHandler}
            />
          </span>

          <div className={classes.buttonSection}>
            <Button
              onClick={() => {
                if (
                  resetPasswordObject.newPassword ===
                    resetPasswordObject.confirmNewPassword &&
                  resetPasswordToken
                ) {
                  verifyResetPassword(resetPasswordToken as string);
                }
              }}
              loading={resetPasswordRequest.isLoading}
              disabled={
                !passwordIsvalid ||
                resetPasswordObject.newPassword !==
                  resetPasswordObject.confirmNewPassword ||
                !resetPasswordObject.newPassword ||
                !resetPasswordObject.confirmNewPassword
              }
            >
              Update password
            </Button>
          </div>
        </form>
      </section>
    </AuthLayout>
  );
};

export default ResetPassword;
