import { useState, ChangeEvent, useEffect } from "react";
import Input from "Components/Input/Input";
import ProfileSectionContainer from "Components/ProfileSectionContainer/ProfileSectionContainer";
import noProfileImage from "Assets/Images/noProfileImage.svg";
import DropdownWithSearch from "Components/DropdownWithSearch/DropdownWithSearch";
import Button from "Components/Button/Button";

import nigerianStatesAndLgas from "Utilities/nigerianStatesAndLgas";
import countries from "Utilities/countries";

import classes from "./About.module.css";
import endpoints from "Services/endpoints";
import { getProfile, TUser, completeProfile } from "Services";
import { useApp } from "Context/AppContext";

const employmentStatusOptions = [
  "Employed (Full-Time)",
  "Employed (Part-Time)",
  "Self-Employed",
  "Unemployed",
  "Student",
];

const educationLevelOptions = [
  "High School Diploma or Equivalent",
  "Associate's Degree",
  "Bachelor's Degree",
  "Master's Degree",
  "Doctoral Degree (Ph.D.)",
  "Vocational or Technical Certification",
  "College, No Degree",
  "High School Student",
  "Other",
];

const About = () => {
  const [profile, setProfile] = useState<TUser>({
    profile_image: "",
    first_name: "",
    last_name: "",
    gender: "",
    country: "",
    state: "",
    phone_number: "",
    bio: "",
    linkedIn_profile: "",
    employement_status: "",
    education: "",
  });
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setNotifications } = useApp();

  // useSWR(endpoints.GET_PROFILE, getProfile, {
  //   dedupingInterval: 0,
  //   onSuccess: (data) => {
  //     setProfileImageUrl(data.profile_image as string);
  //     setProfile({
  //       ...data,
  //     });
  //   },
  // });

  useEffect(() => {
    getProfile(endpoints.GET_PROFILE).then((data) => {
      setProfileImageUrl(data.profile_image as string);
      setProfile(data);
    });
  }, []);

  const handleImage = (e: any) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImageUrl(reader.result as string);
      }
    };

    reader.readAsDataURL(e.target.files[0]);

    setFieldValue("profile_image", e.target.files[0]);
  };

  const setFieldValue = (name: string, value: string) => {
    setProfile((state) => ({ ...state, [name]: value }));
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { target } = event;

    setProfile((state) => ({ ...state, [target.name]: target.value }));
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const profileFormData = new FormData();

      const { profile_image, ...rest } = profile;

      Object.entries(rest).forEach(([key, value]) => {
        profileFormData.append(key, value?.toString() || "");
      });

      profileFormData.append("profile_image", profile_image as any);

      await completeProfile(profileFormData);

      setNotifications([
        {
          title: "Successfully updated contact info",
          severity: "success",
          id: Date.now(),
        },
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.container}>
      <ProfileSectionContainer
        header="About"
        paragraph="Tell us about yourself, we'd like to know you better."
      >
        <div>
          <div className="mb-6">
            <div>
              <div className="flex items-center gap-x-10">
                <img
                  className="w-[80px] h-[80px] object-cover rounded-full"
                  src={profileImageUrl || noProfileImage}
                  alt="Profile"
                />

                <div className={classes.buttonUpper}>
                  <input
                    type="file"
                    id="profilePhoto"
                    accept="image/*"
                    onChange={handleImage}
                  />
                  <label htmlFor="profilePhoto">Upload photo</label>
                  <label
                    onClick={() => {
                      setProfile((state) => ({ ...state, profile_image: "" }));
                    }}
                  >
                    Remove photo
                  </label>
                </div>
              </div>
            </div>

            <p className="text-[13px] mt-4 text-[#737373]">
              Maximum size: 1MB. Supported formats: JPG, GIF, or PNG.
            </p>
          </div>

          <Input
            name="first_name"
            isRequired
            label="First name"
            placeholder="Amirah "
            value={profile?.first_name || ""}
            onChange={handleChange}
          />

          <Input
            isRequired
            name="last_name"
            label="Last name"
            placeholder="Temitope"
            value={profile.last_name || ""}
            onChange={handleChange}
          />

          {/* <Input
            isRequired
            label="Headline"
            name="headline"
            placeholder="Temitope"
            value={profile.headline || ""}
            onChange={handleChange}
          /> */}

          <DropdownWithSearch
            label="Gender"
            options={["Male", "Female"]}
            isRequired
            title="Select"
            selected={profile.gender}
            setSelected={(value) => {
              setFieldValue("gender", value);
            }}
          />

          <div>
            <DropdownWithSearch
              isRequired
              label="Country"
              title="Select Country"
              options={countries.map((item) => item.name)}
              selected={profile.country}
              setSelected={(value) => {
                setFieldValue("country", value);
                setFieldValue("state", "");
              }}
              errorMessage="Country is required"
            />
          </div>

          <div>
            {profile?.country?.toLowerCase() !== "nigeria" ? (
              <Input
                isRequired
                label="State"
                name="state"
                placeholder="e.g New york"
                onChange={handleChange}
                value={profile.state || ""}
                errorMessage="State is required"
              />
            ) : (
              <DropdownWithSearch
                isRequired
                label="State"
                title="Select State"
                options={nigerianStatesAndLgas.map((item) => item.name)}
                selected={profile.state}
                setSelected={(value) => setFieldValue("state", value)}
                errorMessage="State is required"
              />
            )}
          </div>

          <DropdownWithSearch
            title="Select your current status"
            options={employmentStatusOptions}
            label="Employment status"
            isRequired
            errorMessage="Select an option to continue "
            selected={profile.employement_status || ""}
            setSelected={(value) => setFieldValue("employement_status", value)}
          />

          <DropdownWithSearch
            title="Select Education level"
            options={educationLevelOptions}
            label="Education Level"
            isRequired
            errorMessage="Select an option to continue "
            selected={profile.education || ""}
            setSelected={(value) => setFieldValue("education", value)}
          />
        </div>

        <div className={classes.buttonLower}>
          <Button
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Save changes
          </Button>
        </div>
      </ProfileSectionContainer>
    </div>
  );
};

export default About;
