import CloseAccount from './Components/CloseAccount'
import ManageAccounts from './Components/ManageAccounts'
import ManageEmail from './Components/ManageEmail'
// import Notifications from './Components/Notifications'

const ProfileAccountSettings = () => {
  return (
    <section className="pb-20">
      <div className="bg-white mx-[24px] mt-6 rounded-[10px] px-8 py-9 mb-8">
        <ManageAccounts />
      </div>

      <div className="bg-white mx-[24px] mt-6 rounded-[10px] px-8 py-9 mb-8">
        <ManageEmail />
      </div>
      {/* <Notifications /> */}
      <div className="bg-white mx-[24px] mt-6 rounded-[10px] px-8 py-9">
        <CloseAccount />
      </div>
    </section>
  )
}

export default ProfileAccountSettings
