import classes from "./OnboardingLayoutImageSection.module.css";
import ts from "../../Assets/Images/ts.svg";
import signUpImageSection from "../../Assets/Images/signUpImageSection.svg";

const OnboardingLayoutImageSection = () => {
  return (
    <div className={classes.container}>
      <p>
        "I started iṣẹ́ to address the lack of essential tech skills among
        African job seekers. We offer courses to help individuals become
        competitive candidates for remote jobs and transform their careers."
      </p>

      <div>
        <img src={ts} alt="Temi Sharon" />
        <div>
          <h4>Temitope Sharon</h4>
          <p>Founder, ise</p>
        </div>
      </div>

      <img src={signUpImageSection} alt="Ise Students App" />
    </div>
  );
};

export default OnboardingLayoutImageSection;
