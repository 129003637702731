import { Dispatch, SetStateAction, useEffect } from "react";
import Checked from "../../Assets/SVGs/Checked";
import Dot from "../../Assets/SVGs/Dot";
import classes from "./PasswordStrength.module.css";

type PasswordStrengthTypes = {
  password: string;
  setPasswordConditionsValid?: Dispatch<SetStateAction<boolean>>;
};

const conditions = [
  {
    title: "One lowercase character",
    regExp: /[a-z]/,
  },
  {
    title: "One uppercase character",
    regExp: /[A-Z]/,
  },
  {
    title: "One special character",
    regExp: /[!@#$%^&*(),.?":{}|<>]/,
  },
  {
    title: "One number",
    regExp: /\d/,
  },
  {
    title: "Eight characters minimum",
    regExp: /.{8,}/,
  },
];

const PasswordStrength = ({
  password,
  setPasswordConditionsValid,
}: PasswordStrengthTypes) => {
  // Utils

  useEffect(() => {
    if (password && setPasswordConditionsValid) {
      const allConditionsPassed = conditions.every((data) => {
        return data.regExp.test(password);
      });

      setPasswordConditionsValid(allConditionsPassed);
    }
  }, [password]);

  return (
    <section className={classes.container}>
      {conditions?.map((data) => {
        const isSatisfied = data?.regExp?.test(password);

        return (
          <div
            className={`${isSatisfied ? classes.condition : classes.invalid}`}
            key={data?.title}
          >
            {isSatisfied ? <Checked /> : <Dot />}
            <span>{data?.title}</span>
          </div>
        );
      })}
    </section>
  );
};

export default PasswordStrength;
