import { useState } from "react";
import Button from "Components/Button/Button";
import Error from "Components/Error/Error";
import Input from "Components/Input/Input";
import AcceptedModal from "Components/Modals/AcceptedModal/AcceptedModal";
import ProfileSectionContainer from "Components/ProfileSectionContainer/ProfileSectionContainer";
import { useAuth } from "Context/AuthUserContext";
import ChangeEmailComformedModalBody from "./ChangeEmailComformedModalBody";
import ChangeEmailModalWarning from "./ChangeEmailModalWarning";

import classes from "./ManageEmail.module.css";
import { changeEmail } from "Services";
import { useApp } from "Context/AppContext";
import errors from "Utilities/errors";

const ManageEmail = () => {
  // States
  const [displayInput, setDisplayInput] = useState(false);
  const [displayWarningModal, setDisplayWarningModal] = useState(false);
  const [displayEmailChangeConfirmModal, setDisplayEmailChangeConfirmModal] =
    useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setNotifications } = useApp();
  const { user } = useAuth();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      await changeEmail({ email, password });

      setDisplayEmailChangeConfirmModal(true);
    } catch (error: any) {
      setDisplayWarningModal(false);

      const errorMessage = errors.format(error);

      setNotifications([
        {
          title: errorMessage,
          id: Date.now(),
          severity: "error",
        },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ProfileSectionContainer
      header="Manage email"
      paragraph="Provide your new email address. We'll send a confirmation link to this new address to ensure validity."
    >
      <div className={classes.container}>
        {displayWarningModal && (
          <AcceptedModal
            onClick={() => {
              setDisplayWarningModal(false);
            }}
            body={
              <ChangeEmailModalWarning
                isSubmitting={isSubmitting}
                onClick={() => {
                  setDisplayWarningModal(false);
                }}
                onClick2={handleSubmit}
                oldEmail={user?.data?.email || ""}
                newEmail={email}
              />
            }
          />
        )}

        {displayEmailChangeConfirmModal && (
          <AcceptedModal
            onClick={() => {
              setDisplayEmailChangeConfirmModal(false);
            }}
            body={<ChangeEmailComformedModalBody email={email} />}
          />
        )}

        <div className="error">
          <Error type="error">
            You can only change your email once in six months
          </Error>
        </div>

        <div>
          <Input
            label="Account email"
            placeholder="Enter your current iṣẹ́ School password"
            value={user?.data?.email || ""}
            readOnly
          />
        </div>

        <div className="flex">
          {!displayInput && (
            <div>
              <Button
                type="null"
                onClick={() => {
                  setDisplayInput(true);
                }}
              >
                Change email
              </Button>
            </div>
          )}
        </div>

        {displayInput && (
          <>
            <Input
              isRequired
              condition={email !== user?.data?.email}
              errorMessage="Email is invalid"
              type="email"
              label="Enter new account email"
              placeholder="name@gmail.com"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <Input
              type="password"
              label="Enter account password"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />

            <div className={classes.buttonSection}>
              <div className="w-[117px]">
                <Button
                  type="secondary"
                  onClick={() => {
                    setDisplayInput(false);
                  }}
                >
                  Cancel
                </Button>
              </div>

              <Button
                onClick={() => {
                  setDisplayWarningModal(true);
                }}
                disabled={!email || !password}
              >
                Confirm email change
              </Button>
            </div>
          </>
        )}
      </div>
    </ProfileSectionContainer>
  );
};

export default ManageEmail;
