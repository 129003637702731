import Button from 'Components/Button/Button'
import Error from 'Components/Error/Error'

import classes from './ManageEmail.module.css'

type ChangeEmailModalWarningprops = {
  onClick: () => void
  onClick2: () => void
  oldEmail: string
  newEmail: string
  isSubmitting: boolean
}

const ChangeEmailModalWarning = ({
  onClick,
  onClick2,
  oldEmail,
  newEmail,
  isSubmitting,
}: ChangeEmailModalWarningprops) => {
  return (
    <div className="px-16 py-14">
      <h4 className="text-[25px] font-medium">Change email address</h4>

      <p className="text-[#737373] mt-1.5 mb-4">
        Are you sure you want to change the email address associated with your
        account?
      </p>

      <Error type="error" notShowIndicator>
        <div className="text-base text-[#2E2E2E] font-medium px-1">
          Important: Please note that you can only change your email address
          once every 6 months. Ensure that you have access to the new email
          address
        </div>
      </Error>

      <p>Current Email Address: {oldEmail || '[Old Email Address]'}</p>
      <p className="mb-3">
        New Email Address: {newEmail || '[New Email Address]'}
      </p>

      <p className="mb-1 text-[#737373]">
        By confirming, the following actions will take place:
      </p>

      <ol className="list-decimal pl-7 text-[#737373]">
        <li>You will be logged out of your current session.</li>
        <li>
          You will receive email notifications confirming this change to both
          your old and new email addresses.
        </li>
        <li>To log back in, you must use your new email address.</li>
      </ol>

      <div className={classes.buttonSection}>
        <div className="w-[117px]">
          <Button type="secondary" onClick={onClick}>
            Cancel
          </Button>
        </div>
        <Button loading={isSubmitting} onClick={onClick2}>
          Confirm email change
        </Button>
      </div>
    </div>
  )
}

export default ChangeEmailModalWarning
