import DropdownWithSearch from "Components/DropdownWithSearch/DropdownWithSearch";
import Button from "Components/Button/Button";
import { Dispatch, SetStateAction } from "react";
import { TOnboarding } from "../typings";
import { useSearchParams } from "react-router-dom";
import Svgs from "Assets/Svgs";

const BackgroundAndSkills = (props: {
  onboarding: TOnboarding;
  setOnboarding: Dispatch<SetStateAction<TOnboarding>>;
  handleSubmit: () => void;
  isSubmitting: boolean;
}) => {
  const { onboarding, setOnboarding, handleSubmit, isSubmitting } = props;

  const [_, setSearchParams] = useSearchParams();

  const employmentStatusOptions = [
    "Employed (Full-Time)",
    "Employed (Part-Time)",
    "Self-Employed",
    "Unemployed",
    "Student",
  ];

  const educationLevelOptions = [
    "High School Diploma or Equivalent",
    "Associate's Degree",
    "Bachelor's Degree",
    "Master's Degree",
    "Doctoral Degree (Ph.D.)",
    "Vocational or Technical Certification",
    "College, No Degree",
    "High School Student",
    "Other",
  ];

  const aboutUsOptions = ["LinkedIn", "Twitter", "From a friend"];

  const setFieldValue = (name: string, value: string) => {
    setOnboarding((state) => ({ ...state, [name]: value }));
  };

  return (
    <section>
      <div className="mb-10">
        <h2 className="text-2xl font-semibold mb-2">Background and skills</h2>
        <p>Let’s get to know you. It’ll take less than 5 minutes.</p>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div>
          <DropdownWithSearch
            title="Select your current status"
            options={employmentStatusOptions}
            label="Employment status"
            isRequired
            errorMessage="Select an option to continue "
            selected={onboarding.employement_status}
            setSelected={(value) => setFieldValue("employement_status", value)}
          />
        </div>

        <div>
          <DropdownWithSearch
            title="Select Education level"
            options={educationLevelOptions}
            label="Education Level"
            isRequired
            errorMessage="Select an option to continue "
            selected={onboarding.education}
            setSelected={(value) => setFieldValue("education", value)}
          />
        </div>

        <div>
          <DropdownWithSearch
            title="Select"
            options={aboutUsOptions}
            label="How did you hear about us?"
            isRequired
            errorMessage="Select an option to continue "
            selected={onboarding.about_us}
            setSelected={(value) => setFieldValue("about_us", value)}
          />
        </div>

        <div className="flex items-center gap-x-8">
          <Button
            type="secondary"
            onClick={() => {
              setSearchParams({
                step: "1",
              });
            }}
          >
            <span className="flex items-center gap-x-2.5">
              <Svgs.Arrow className="text-[#664EFE] rotate-180" />
              <span>Previous</span>
            </span>
          </Button>

          <Button
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={
              !onboarding.education ||
              !onboarding.employement_status ||
              !onboarding.about_us
            }
          >
            <span className="flex items-center gap-x-2.5">
              <span>Next</span>
              <Svgs.Arrow className="text-white" />
            </span>
          </Button>
        </div>
      </form>
    </section>
  );
};

export default BackgroundAndSkills;
