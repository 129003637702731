import { useGoogleLogin } from "@react-oauth/google";

import OnboardingLayout from "../../Components/OnboardingLayout/OnboardingLayout";
import classes from "./SignUp.module.css";
import Input from "../../../Components/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import iseLogo from "../../../Assets/Images/iseLogo.svg";
import { useState } from "react";
import { useAuth } from "../../../Context/AuthUserContext";
import Error from "../../../Components/Error/Error";
import { capitalize } from "../../../HelperFunctions/capitalize";
import Google from "../../Assets/SVGs/Google";
import PasswordStrength from "../../Components/PasswordStrength/PasswordStrength";
import { authenticateWithGoogle } from "Services";
import errors from "Utilities/errors";
import { setToken, TokenKeys } from "v2/Utilities/token";

const SignUp = () => {
  // COntext
  const {
    userLoginInfo,
    setUserLoginInfo,
    signUp,
    signupRequest,
    setSignInRequest,
  } = useAuth();

  const navigate = useNavigate();

  // States
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Utils
  const changeHandler = (e: any) => {
    setUserLoginInfo((prevState) => {
      return { ...prevState, [e?.target?.name]: e.target?.value };
    });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setIsSubmitting(true);

      authenticateWithGoogle({ token: tokenResponse.access_token })
        .then((res) => {
          setSignInRequest((state) => ({ ...state, data: res.data.students }));

          setToken(TokenKeys.ACCESS_TOKEN, res.data.accessToken);
          setToken(TokenKeys.REFRESH_TOKEN, res.data.refreshToken);

          navigate("/dashboard");
        })
        .catch((error) => {
          const errorMessage = errors.format(error);

          setSignInRequest((state) => ({
            ...state,
            data: null,
            error: errorMessage,
          }));
        })
        .finally(() => setIsSubmitting(false));
    },
  });

  return (
    <>
      <OnboardingLayout>
        <section className={classes.container}>
          <img src={iseLogo} alt="Ise Logo" className={classes.logo} />
          <div className={classes.header}>
            <h4>Create account</h4>
            <p>
              Create an account with iṣẹ́ School to kickstart your tech career
            </p>
          </div>

          <Button type="bordered" onClick={() => handleGoogleLogin()}>
            <Google />
            <span>Sign up with Google</span>
          </Button>

          <div className={classes.continueWith}>
            <hr />
            <span>or continue with your email</span>
            <hr />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {signupRequest?.error && (
              <Error type="error">{capitalize(signupRequest.error)}</Error>
            )}
            <Input
              label="Email Address"
              isRequired
              placeholder="name@gmail.com"
              value={userLoginInfo.email as string}
              onChange={changeHandler}
              name="email"
            />
            <span>
              <Input
                label="Password"
                isRequired
                type="password"
                placeholder="************"
                name="password"
                onChange={changeHandler}
                value={userLoginInfo.password as string}
              />
            </span>

            {userLoginInfo?.password && (
              <PasswordStrength
                password={userLoginInfo?.password}
                setPasswordConditionsValid={setPasswordIsValid}
              />
            )}

            {/* <div className={classes.rememberAndForgot}>
              <div>
                <input
                  type="checkbox"
                  id="remember"
                  checked={userLoginInfo.isSubscribed}
                  name="isSubscribed"
                  onChange={() => {
                    setUserLoginInfo((prevState) => {
                      return {
                        ...prevState,
                        isSubscribed: !prevState.isSubscribed,
                      };
                    });
                  }}
                />
                <label htmlFor="remember">
                  I want to receive updates from ise
                </label>
              </div>
            </div> */}

            <div className={classes.buttonSection}>
              <Button
                onClick={() => {
                  signUp();
                }}
                disabled={
                  !userLoginInfo?.email ||
                  !userLoginInfo?.password ||
                  !passwordIsValid
                }
                loading={signupRequest.isLoading || isSubmitting}
              >
                Sign up
              </Button>
            </div>
          </form>

          <div className={classes.alternate}>
            Already have an account? <Link to="/sign-in">Login</Link>
          </div>
        </section>
      </OnboardingLayout>
    </>
  );
};

export default SignUp;
