import { useContext } from "react";
import { Link } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import Error from "../../../Components/Error/Error";
import Input from "../../../Components/Input/Input";
import { AuthUserContext } from "../../../Context/AuthUserContext";
import { inputChangeHandler } from "../../../HelperFunctions/inputChangeHandler";
import BackArrow from "../../Assets/SVGs/BackArrow";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import { emaailRegex } from "../../Utilities/constants";
import classes from "../SignIn/SignIn.module.css";

const ForgotPassword = () => {
  // Context
  const {
    resetPassword,
    resetPasswordRequest,
    userLoginInfo,
    setUserLoginInfo,
  } = useContext(AuthUserContext);

  return (
    <AuthLayout isAlt>
      <section className={classes.container}>
        <div className={classes.header}>
          <h4>Forgot password</h4>
          <p>
            Enter the email you used to sign up on iṣẹ́ EdTech and we'll send you
            password reset instructions.
          </p>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {resetPasswordRequest.error && (
            <Error type="error">{resetPasswordRequest?.error}</Error>
          )}

          <Input
            label="Email Address"
            isRequired
            placeholder="Enter email address"
            type="email"
            onChange={(e) => {
              inputChangeHandler(e, setUserLoginInfo);
            }}
            value={userLoginInfo.email as string}
            name="email"
            condition={emaailRegex.test(userLoginInfo?.email)}
            errorMessage="Enter a valid email address"
          />

          <div className={classes.buttonSection}>
            <Button
              onClick={() => {
                resetPassword();
              }}
              loading={resetPasswordRequest.isLoading}
              disabled={
                !userLoginInfo?.email || !emaailRegex.test(userLoginInfo?.email)
              }
            >
              Send instructions
            </Button>
          </div>
        </form>

        <div className={classes.alternate}>
          <BackArrow />
          <Link to="/sign-in">Back to Log in</Link>
        </div>
      </section>
    </AuthLayout>
  );
};

export default ForgotPassword;
