export enum TokenKeys {
  ACCESS_TOKEN = "iseAccessToken",
  REFRESH_TOKEN = "iseRefreshToken",
}

export const setToken = (tokenKey: TokenKeys, token: string) => {
  localStorage.setItem(tokenKey, token);
};

export const removeToken = (tokenKey: TokenKeys) => {
  localStorage.removeItem(tokenKey);
};
