import { Navigate, Route, Routes } from "react-router-dom";
import RequireAuth from "./Components/RequireAuth/RequireAuth";
import RequirePayWall from "./Components/RequirePayWall/RequirePayWall";
import ErrorPage from "./Containers/404Page/404Page";
import CourseSearchContainer from "./Containers/CourseSearchContainer/CourseSearchContainer";
import PaymentMain from "./Containers/PaymentMain/PaymentMain";
import ReferralPolicy from "./Containers/ReferralPolicy/ReferralPolicy";
import ReviewQuizAnswers from "./Containers/ReviewQuizAnswers/ReviewQuizAnswers";
import CollaborationHubPage from "./Pages/CollaborationHubPage";
import ContactSupportPage from "./Pages/ContactSupportPage";
import CourseDetail from "./Pages/CourseDetail";
import CourseDetailPage from "./Pages/CourseDetailPage";
import CourseFeedback from "./Pages/CourseFeedback";
import CourseLessons from "./Pages/CourseLessons";
import CourseLessonScreen from "./Pages/CourseLessonScreen";
import Courses from "./Pages/Courses";
import Dashboard from "./Pages/Dashboard";
import Eligibility from "./Pages/Eligibility";
import PaymentChoicePage from "./Pages/PaymentChoicePage";
import ProgramAdvisor from "./Pages/ProgramAdvisor";
import ReferralPage from "./Pages/ReferralPage";
import ResetPassword from "./v2/Containers/ResetPassword/ResetPassword";
import ResetPasswordCheckEmail from "./v2/Containers/ResetPasswordCheckEmail/ResetPasswordCheckEmail";
import Support from "./Pages/Support";
import VerifyEmail from "./v2/Containers/VerifyEmail/VerifyEmail";
import SignUp from "./v2/Containers/SignUp/SignUp";
import SignIn from "./v2/Containers/SignIn/SignIn";
import ForgotPassword from "./v2/Containers/ForgotPassword/ForgotPassword";
import Onboarding from "./Pages/Onboarding";
import Profile from "Modules/Profile";
import Layout from "Components/Layout/Layout";

function App() {
  return (
    <Routes>
      <Route path="*" element={<ErrorPage />} />
      <Route path="/" element={<Navigate to="/dashboard"></Navigate>} />

      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/forgot-password/check-email"
        element={<ResetPasswordCheckEmail />}
      />
      <Route path="/verify-email" element={<VerifyEmail />} />

      <Route element={<RequireAuth />}>
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/explore-courses" element={<CourseSearchContainer />} />
        <Route path="/courses/:courseId" element={<CourseDetailPage />} />

        <Route element={<RequirePayWall />}>
          <Route path="/courses/feedback" element={<CourseFeedback />} />
          <Route
            path="/courses/:courseId/overview"
            element={<CourseLessons />}
          />
          <Route
            path="/courses/:courseId/view"
            element={<CourseLessonScreen />}
          />

          <Route
            path="/courses/:courseId/:week/:lessonType/:lesson/quiz-question/review"
            element={<ReviewQuizAnswers />}
          />
          <Route
            path="/courses/:courseId/course-detail"
            element={<CourseDetail />}
          />
        </Route>

        <Route path="/eligibility/:courseId" element={<Eligibility />} />
        <Route path="/contact-student-advisor" element={<ProgramAdvisor />} />

        <Route path="/payment/:courseId" element={<PaymentChoicePage />} />
        <Route
          path="/payment/:courseId/:paymentPlan"
          element={<PaymentMain />}
        />

        <Route path="/collaboration-hub" element={<CollaborationHubPage />} />

        {/* <Route path="/profile/:subProfile" element={<ProfilePage />} /> */}
        <Route
          path="/profile/:subProfile"
          element={
            <Layout>
              <Profile />
            </Layout>
          }
        />

        <Route path="/support" element={<Support />} />
        <Route
          path="/support/contact-support"
          element={<ContactSupportPage />}
        />

        <Route path="/referral" element={<ReferralPage />} />
        <Route path="/referral/policy" element={<ReferralPolicy />} />
      </Route>

      <Route path="/test" element={<Onboarding />} />
    </Routes>
  );
}

export default App;
