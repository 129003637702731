import classes from "./AuthLayout.module.css";
import iseLogo from "../../../Assets/Images/iseLogo.svg";
import {
  AuthLayoutSvg1,
  AuthLayoutSvg2,
  AuthLayoutSvg3,
  AuthLayoutSvg4,
} from "../../Assets/SVGs/AuthLayoutSvgs";

type AuthLayoutTypes = {
  children: React.ReactNode;
  isAlt?: boolean;
};

const AuthLayout = ({ children, isAlt }: AuthLayoutTypes) => {
  return (
    <section
      className={`${classes.container} ${isAlt ? classes.alt : undefined}`}
    >
      <div className={classes.header}>
        <img src={iseLogo} alt="Ise school" />
      </div>

      <div className={classes.body}>
        <div className={classes.children}>{children}</div>
      </div>

      <AuthLayoutSvg1 />
      <AuthLayoutSvg2 />
      <AuthLayoutSvg3 />
      <AuthLayoutSvg4 />
    </section>
  );
};

export default AuthLayout;
