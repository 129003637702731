import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";

import classes from "./SignIn.module.css";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import { useAuth } from "../../../Context/AuthUserContext";
import Error from "../../../Components/Error/Error";
import Input from "../../../Components/Input/Input";
import { capitalize } from "../../../HelperFunctions/capitalize";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import Google from "../../Assets/SVGs/Google";
import { authenticateWithGoogle } from "Services";
import errors from "Utilities/errors";
import { setToken, TokenKeys } from "v2/Utilities/token";

const SignIn = () => {
  const {
    setSignInRequest,
    signIn,
    signInRequest,
    userLoginInfo,
    setUserLoginInfo,
    iseUserFirstName,
  } = useAuth();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Utils
  const changeHandler = (e: any) => {
    setUserLoginInfo((prevState) => {
      return { ...prevState, [e?.target?.name]: e.target?.value };
    });
  };

  const changeHandler2 = (e: any) => {
    setUserLoginInfo((prevState) => {
      return { ...prevState, [e?.target?.name]: !e.target.checked };
    });
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setIsSubmitting(true);

      authenticateWithGoogle({ token: tokenResponse.access_token })
        .then((res) => {
          setSignInRequest((state) => ({ ...state, data: res.data.students }));

          setToken(TokenKeys.ACCESS_TOKEN, res.data.accessToken);
          setToken(TokenKeys.REFRESH_TOKEN, res.data.refreshToken);

          navigate("/dashboard");
        })
        .catch((error) => {
          const errorMessage = errors.format(error);

          setSignInRequest((state) => ({
            ...state,
            data: null,
            error: errorMessage,
          }));
        })
        .finally(() => setIsSubmitting(false));
    },
  });

  return (
    <AuthLayout>
      <section className={classes.container}>
        <div className={classes.header}>
          <h4>Welcome back{`, ${iseUserFirstName || ""}`}</h4>
          <p>Log in to continue your learning journey.</p>
        </div>

        <Button type="bordered" onClick={() => handleGoogleLogin()}>
          <Google />
          <span>Continue with Google</span>
        </Button>

        <div className={classes.continueWith}>
          <hr />
          <span>or continue with your email</span>
          <hr />
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {signInRequest?.error && (
            <Error type="error">{capitalize(signInRequest.error)}</Error>
          )}
          <Input
            label="Email"
            isRequired
            placeholder="name@gmail.com"
            value={userLoginInfo.email as string}
            onChange={changeHandler}
            name="email"
          />
          <span>
            <Input
              label="Password"
              isRequired
              type="password"
              placeholder="************"
              name="password"
              onChange={changeHandler}
              value={userLoginInfo.password as string}
            />
          </span>

          <div className={classes.rememberAndForgot}>
            <div>
              <input
                type="checkbox"
                id="remember"
                checked={userLoginInfo.remember}
                name="remember"
                onChange={changeHandler2}
              />
              <span>Remember Me</span>
            </div>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>

          <div className={classes.buttonSection}>
            <Button
              onClick={() => {
                signIn();
              }}
              loading={signInRequest.isLoading || isSubmitting}
            >
              Log in
            </Button>
          </div>
        </form>

        <div className={classes.alternate}>
          Don' t have an account? <Link to="/sign-up">Sign up</Link>
        </div>
      </section>
    </AuthLayout>
  );
};

export default SignIn;
