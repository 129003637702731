import { useContext } from "react";
import { AuthUserContext } from "../../../Context/AuthUserContext";
import {
  UserOnboardingSvg1,
  UserOnboardingSvg2,
  UserOnboardingSvg3,
  UserOnboardingSvg4,
  UserOnboardingSvg5,
} from "../../Assets/SVGs/UserOnboardingSvgs";
import classes from "./UserOnboardingLayout.module.css";

type UserOnboardingLayoutTypes = {
  children: React.ReactNode;
};

const UserOnboardingLayout = ({ children }: UserOnboardingLayoutTypes) => {
  // Context
  const { searchParams } = useContext(AuthUserContext);

  // Steps
  const steps = [1, 2];

  const userStep = searchParams.get("step");

  return (
    <section className={classes.container}>
      <div className={classes.decorationSection}>
        <UserOnboardingSvg1 />
        <UserOnboardingSvg2 />
        <UserOnboardingSvg3 />
      </div>
      <div className={classes.children}>
        <UserOnboardingSvg4 />
        <UserOnboardingSvg5 />

        <div className={classes.body}>
          {Number(userStep) < 3 && (
            <div className={classes.stepIndicator}>
              {steps.map((data) => {
                return (
                  <div
                    className={`${classes.step} ${
                      userStep && userStep >= data.toString()
                        ? classes.active
                        : classes.inActive
                    }`}
                    key={data}
                  >
                    <hr />
                    <span>{data}</span>
                  </div>
                );
              })}
            </div>
          )}
          <div className={classes.childrenContainer}>{children}</div>
        </div>
      </div>
    </section>
  );
};

export default UserOnboardingLayout;
