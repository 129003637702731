import { AxiosResponse } from "axios";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import requestHandler from "../HelperFunctions/requestHandler";
import { setNotiticationFunction } from "../Utilities/setNotificationsFunction";
import { requestType } from "./AuthUserContext";
import { backend_url } from "../Utilities/global";

type AppContextProviderProps = {
  children: React.ReactNode;
};

type AppContextProps = {
  screenWidthState: number;
  showGetStarted: {
    rightCta: boolean;
    dashboard: boolean;
    showModdal: boolean;
  };
  setShowGetStarted: Dispatch<
    SetStateAction<{
      rightCta: boolean;
      dashboard: boolean;
      showModdal: boolean;
    }>
  >;
  displayRatemodal: boolean;
  setDisplayRateModal: Dispatch<SetStateAction<boolean>>;
  displaySharemodal: boolean;
  setDisplayShareModal: Dispatch<SetStateAction<boolean>>;
  notifications: notificationsType;
  setNotifications: Dispatch<SetStateAction<notificationsType>>;
  copyToClipBoard: (text: string) => void;
  setNotificationSettingRequest: () => void;
  getNotificationSettingRequest: () => void;
  notificationSettings: {
    course_updates: boolean;
    newsletter: boolean;
    reminders: boolean;
    announcements: boolean;
    feedback: boolean;
  };
  setNotificationSettings: Dispatch<
    SetStateAction<{
      course_updates: boolean;
      newsletter: boolean;
      reminders: boolean;
      announcements: boolean;
      feedback: boolean;
    }>
  >;
  getNotificationSettingsRequestObject: requestType;
};

export type notificationsType =
  | {
      title: string;
      severity: "success" | "error" | "mid";
      description?: string;
      id: string | number;
    }[]
  | null
  | undefined;

export const AppContext = createContext({} as AppContextProps);

const AppContextProvider = ({ children }: AppContextProviderProps) => {
  // Utils
  const screenWidth = window.innerWidth;

  // States
  const [screenWidthState, setScreenWidthState] = useState<number>(screenWidth);
  const [showGetStarted, setShowGetStarted] = useState<{
    rightCta: boolean;
    dashboard: boolean;
    showModdal: boolean;
  }>({
    rightCta: false,
    dashboard: true,
    showModdal: false,
  });
  const [displayRatemodal, setDisplayRateModal] = useState(false);
  const [displaySharemodal, setDisplayShareModal] = useState(false);
  const [notifications, setNotifications] = useState<notificationsType>(null);

  //   Effects
  useEffect(() => {
    setScreenWidthState(screenWidth);
  }, [screenWidth]);

  const copyToClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setNotiticationFunction(
        setNotifications,
        "Your text has been copied to the clipboard",
        "success"
      );
    } catch (err) {
      setNotiticationFunction(
        setNotifications,
        "Your text was not  copied to the clipboard"
      );
    }
  };

  const [notificationSettings, setNotificationSettings] = useState({
    course_updates: true,
    newsletter: true,
    reminders: true,
    announcements: true,
    feedback: true,
  });

  const setNotificationSettingRequest = () => {
    requestHandler({
      url: `${backend_url}/api/ise/v1/students/notifications/settings`,
      method: "POST",
      data: notificationSettings,
    })
      .then((res) => {
        setNotiticationFunction(
          setNotifications,
          (res as AxiosResponse)?.data,
          "success"
        );
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err?.request
            ? "There was an issue making this request"
            : err.message
        );
      });
  };

  const [
    getNotificationSettingsRequestObject,
    setGetNotificationSettingsRequestObject,
  ] = useState<requestType>({
    isLoading: false,
    data: null,
  });

  const getNotificationSettingRequest = () => {
    setGetNotificationSettingsRequestObject({
      isLoading: true,
      data: null,
    });
    requestHandler({
      url: `${backend_url}/api/ise/v1/students/notifications/settings`,
      method: "GET",
    })
      .then((res) => {
        setGetNotificationSettingsRequestObject({
          isLoading: false,
          data: (res as AxiosResponse)?.data,
        });
        setNotificationSettings({
          announcements: (res as any)?.data?.announcements || false,
          course_updates: (res as any)?.data?.course_updates || false,
          newsletter: (res as any)?.data?.newsletter || false,
          reminders: (res as any)?.data?.reminders || false,
          feedback: (res as any)?.data?.feedback || false,
        });
      })
      .catch((err) => {
        setNotiticationFunction(
          setNotifications,
          err.response?.data?.error
            ? err.response?.data?.error?.responseMessage
            : !err.response?.data?.error
            ? err.response?.data?.responseMessage.toString()
            : err?.request
            ? "There was an issue making this request"
            : err.message
        );
      });
  };

  return (
    <AppContext.Provider
      value={{
        screenWidthState,
        showGetStarted,
        setShowGetStarted,
        displayRatemodal,
        setDisplayRateModal,
        displaySharemodal,
        setDisplayShareModal,
        notifications,
        setNotifications,
        copyToClipBoard,
        setNotificationSettingRequest,
        notificationSettings,
        setNotificationSettings,
        getNotificationSettingRequest,
        getNotificationSettingsRequestObject,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);

export default AppContextProvider;
