export const AuthLayoutSvg1 = () => {
  return (
    <svg
      width="147"
      height="234"
      viewBox="0 0 147 234"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50.6639"
        cy="95.6639"
        r="75"
        transform="rotate(19.4127 50.6639 95.6639)"
        fill="#2BA792"
      />
      <circle
        cx="-7.33998"
        cy="137.664"
        r="75"
        transform="rotate(19.4127 -7.33998 137.664)"
        fill="#664EFE"
      />
    </svg>
  );
};

export const AuthLayoutSvg2 = () => {
  return (
    <svg
      width="217"
      height="74"
      viewBox="0 0 217 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.3474 137.632C29.648 115.466 -13.909 68.2155 4.31059 21.7677C20.4 -19.2495 79.4669 13.9899 123.496 12.3363C155.515 11.1337 194.644 -13.8592 210.426 14.0265C228.439 45.8527 204.486 83.02 177.416 107.608C148.132 134.209 109.791 155.208 74.3474 137.632Z"
        fill="#FDC500"
      />
    </svg>
  );
};

export const AuthLayoutSvg3 = () => {
  return (
    <svg
      width="146"
      height="241"
      viewBox="0 0 146 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="95.6639"
        cy="95.6639"
        r="75"
        transform="rotate(19.4127 95.6639 95.6639)"
        fill="#664EFE"
      />
      <circle
        cx="161.664"
        cy="144.664"
        r="75"
        transform="rotate(19.4127 161.664 144.664)"
        fill="#FDC500"
      />
    </svg>
  );
};

export const AuthLayoutSvg4 = () => {
  return (
    <svg
      width="218"
      height="65"
      viewBox="0 0 218 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M144.781 -72.2663C189.045 -49.2448 231.685 -1.16504 212.576 44.9237C195.7 85.6238 137.283 51.2543 93.2309 52.0606C61.1943 52.647 21.5921 76.8826 6.34923 48.6985C-11.0476 16.5317 13.6156 -20.168 41.1532 -44.2311C70.944 -70.2631 109.682 -90.5208 144.781 -72.2663Z"
        fill="#664EFE"
      />
    </svg>
  );
};
