import { Link } from "react-router-dom";
import AuthLayout from "../../Components/AuthLayout/AuthLayout";
import classes from "../SignIn/SignIn.module.css";
import success from "../../../Assets/Gifs/success.gif";
import Button from "../../../Components/Button/Button";
import { useContext } from "react";
import { AuthUserContext } from "../../../Context/AuthUserContext";

const ResetPasswordCheckEmail = () => {
  // Context
  const { resetPassword, resetPasswordRequest } = useContext(AuthUserContext);

  return (
    <AuthLayout>
      <section className={classes.container}>
        <div className={classes.header}>
          <img src={success} alt="Success" />
          <h4>Check your email</h4>
          <p>
            We just sent you a password reset email, click on the link to reset
            your password
          </p>
        </div>

        <Button
          onClick={() => (window.location.href = "mailto:")}
          loading={resetPasswordRequest?.isLoading}
        >
          Open email app
        </Button>

        <p>Didn't receive the email? Check your spam or junk folder</p>

        <p>
          Still can’t find the email?{" "}
          <span onClick={resetPassword}>Resend email</span>
        </p>

        <div className={classes.continueWith}>
          <hr />
          <span>or </span>
          <hr />
        </div>

        <div
          className={classes.alternate}
          style={{
            textAlign: "left",
            justifyContent: "flex-start",
          }}
        >
          Remember your password? <Link to="/sign-in">Back to login</Link>
        </div>
      </section>
    </AuthLayout>
  );
};

export default ResetPasswordCheckEmail;
