import Button from "Components/Button/Button";
import TextArea from "Components/TextArea/TextArea";
import classes from "./ProfileAccountCloseAccount.module.css";

type DeleteDisclaimerModalBodyProps = {
  onClick: () => void;
  onClick2: () => void;
  isSubmitting: boolean;
};

const Close = () => {
  return (
    <svg
      className="w-5 h-5"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 18.5L18 6.5M6 6.5L18 18.5"
        stroke="#2E2E2E"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const DeleteDisclaimerModalBody = ({
  onClick,
  onClick2,
  isSubmitting,
}: DeleteDisclaimerModalBodyProps) => {
  return (
    <div className="md:w-[876px] px-20 py-20">
      <h2 className="text-[25px] font-medium">
        Are you sure you want to close your account?
      </h2>

      <p className="mt-2.5 mb-7 text-[#737373]">
        Closing your account is a permanent step. Your data, profile, and
        preferences will be deleted.
      </p>

      <TextArea
        label="Reason (optional)"
        placeholder="Please tell us why you want to close your account"
      />

      <div className={classes.modalButtonSection}>
        <div className="w-[117px]">
          <Button type="bordered" onClick={onClick}>
            Cancel
          </Button>
        </div>

        <Button type="delete" loading={isSubmitting} onClick={onClick2}>
          Close account
        </Button>
      </div>
    </div>
  );
};

export default DeleteDisclaimerModalBody;
