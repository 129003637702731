import About from './Components/About'
// import AdditionalDetails from './Components/AdditionalDetails'
// import SkillsAndExperience from './Components/SkillsAndExperience'
// import Certifications from './Components/Certifications'

import classes from './ProfileDetails.module.css'

const ProfileDetails = () => {
  return (
    <section className={classes.container}>
      <About />
      {/* <AdditionalDetails />
      <SkillsAndExperience />
      <Certifications /> */}
    </section>
  )
}

export default ProfileDetails
