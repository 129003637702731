import { useContext, useEffect, useState } from "react";
import { AuthUserContext } from "../../Context/AuthUserContext";
import UserOnboardingLayout from "../../v2/Components/UserOnboardingLayout/UserOnboardingLayout";
import ProfileInformation from "./Components/ProfileInformation/index";
// import UserGetToKnowYou from "../UserGetToKnowYou/UserGetToKnowYou";
import BackgroundAndSkills from "./Components/BackgroundAndSkills";
import ProfileCreated from "./Components/ProfileCreated";
import { TOnboarding } from "./typings";
import { completeProfile } from "Services";

const UserOnboarding = () => {
  // Context
  const { setSearchParams } = useContext(AuthUserContext);

  // Search Params
  const currentParams = new URLSearchParams(window.location.search);
  const userStep = currentParams.get("step");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!userStep) {
      currentParams.set("step", "1");
      setSearchParams(currentParams.toString());
    }

    // eslint-disable-next-line
  }, [userStep]);

  const [onboarding, setOnboarding] = useState<TOnboarding>({
    profile_image: "",
    first_name: "",
    last_name: "",
    gender: "",
    country: "",
    state: "",
    employement_status: "",
    education: "",
    about_us: "",
    onboarding_complete: false,
  });

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const onboardingFormData = new FormData();

      const { profile_image, ...rest } = onboarding;

      Object.entries({ ...rest, onboarding_complete: true }).forEach(
        ([key, value]) => {
          console.log(key, value);
          onboardingFormData.append(key, value.toString());
        }
      );

      onboardingFormData.append("profile_image", profile_image);

      await completeProfile(onboardingFormData);

      setSearchParams({ step: "3" });
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <UserOnboardingLayout>
      {userStep === "1" ? (
        <ProfileInformation
          onboarding={onboarding}
          setOnboarding={setOnboarding}
        />
      ) : userStep === "2" ? (
        <BackgroundAndSkills
          onboarding={onboarding}
          setOnboarding={setOnboarding}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      ) : userStep === "3" ? (
        <ProfileCreated />
      ) : null}
    </UserOnboardingLayout>
  );
};

export default UserOnboarding;
