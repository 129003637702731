import { useContext, useState } from 'react'
import Button from 'Components/Button/Button'
import Input from 'Components/Input/Input'
import ProfileSectionContainer from 'Components/ProfileSectionContainer/ProfileSectionContainer'
import { AuthUserContext } from 'Context/AuthUserContext'
import { changeHandler } from 'Utilities/inputChangeHandler'
import PasswordStrength from './Components/PasswordStrength/PasswordStrength'

import classes from './ManageAccounts.module.css'
import { useNavigate } from 'react-router-dom'
import { useApp } from 'Context/AppContext'
import { changePassword } from 'Services'

const ManageAccounts = () => {
  // Context
  // const {
  //   passwordUpdate,
  //   setPasswordUpdate,
  //   passwordUpdateRequestObject,
  //   updatePasswordHandler,
  // } = useContext(AuthUserContext)

  // States
  const [displayInput, setDiplayInput] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordIsValid, setPasswordIsValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { setNotifications } = useApp()

  const handleReset = () => {
    setCurrentPassword('')
    setPassword('')
    setConfirmPassword('')
    setPasswordIsValid(false)
    setDiplayInput(false)
  }

  const handleSubmit = async () => {
    console.log({
      oldPassword: currentPassword,
      newPassword: password,
      confirmPassword,
    })

    try {
      setIsSubmitting(true)

      const { data: response } = await changePassword({
        oldPassword: currentPassword,
        newPassword: password,
        confirmPassword,
      })

      setNotifications([
        {
          title: response,
          id: Date.now(),
          severity: 'success',
        },
      ])

      handleReset()
    } catch (error: any) {
      const errorMessage = error.response.data.error.responseMessage
      console.log(error)
      setNotifications([
        {
          title: errorMessage,
          id: Date.now(),
          severity: 'error',
        },
      ])
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div>
      <ProfileSectionContainer
        header="Manage password"
        paragraph="Keep your account secure. Update your password here."
      >
        <Input
          isRequired
          label="Current password"
          name="oldPassword"
          type="password"
          placeholder="Enter current password"
          errorMessage="Current password is required"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value)
          }}
        />

        <div className="flex">
          {!displayInput && (
            <div>
              <Button
                type="null"
                onClick={() => {
                  setDiplayInput(true)
                }}
              >
                Change password
              </Button>
            </div>
          )}
        </div>

        {displayInput && (
          <>
            <Input
              isRequired
              label="New password"
              name="newPassword"
              type="password"
              placeholder="Enter new password "
              tip="Must be at least 8 characters"
              errorMessage="New password is required"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />

            {password && (
              <PasswordStrength
                password={password}
                setPasswordConditionsValid={setPasswordIsValid}
              />
            )}

            <Input
              isRequired
              label="Confirm new password"
              name="confirmPassword"
              type="password"
              placeholder="Enter new password "
              tip="Must match the new password"
              errorMessage="Confirm password is required"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value)
              }}
            />

            <div className={classes.buttonSection}>
              <Button
                type="secondary"
                onClick={() => {
                  setDiplayInput(false)
                }}
              >
                Cancel
              </Button>

              <Button
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={
                  !passwordIsValid ||
                  password !== confirmPassword ||
                  !password ||
                  !confirmPassword
                }
              >
                Save new password
              </Button>
            </div>
          </>
        )}
      </ProfileSectionContainer>
    </div>
  )
}

export default ManageAccounts
