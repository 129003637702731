import OnboardingLayoutImageSection from "../../Containers/OnboardingLayoutImageSection/OnboardingLayoutImageSection";
import classes from "./OnboardingLayout.module.css";

type OnboardingLayoutTypes = {
  children: React.ReactNode;
};

const OnboardingLayout = ({ children }: OnboardingLayoutTypes) => {
  return (
    <section className={classes.container}>
      <div className={classes.textSection}>{children}</div>
      <div className={classes.imageSection}>
        <OnboardingLayoutImageSection />
      </div>
    </section>
  );
};

export default OnboardingLayout;
