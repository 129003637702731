import { useState } from "react";
import Button from "Components/Button/Button";
import AcceptedModal from "Components/Modals/AcceptedModal/AcceptedModal";
import ProfileSectionContainer from "Components/ProfileSectionContainer/ProfileSectionContainer";
import DeleteDisclaimerModalBody from "./DeleteDisclaimerModalBody";
import DeleteSuccessfulModalBody from "./DeleteSuccessfulModalBody";
import classes from "./ProfileAccountCloseAccount.module.css";
import { closeAccount } from "Services";
import { useApp } from "Context/AppContext";
import errors from "Utilities/errors";

const ProfileAccountCloseAccount = () => {
  const closeDisclaimers = [
    "Closing your account is a permanent action. You can’t undo it",
    "If you close your account and later decide to create a new one, you will not be able to use the same username or email address.",
    "If you have any active subscriptions, they will be canceled.",
    "If you have any questions, please contact us before closing your account.",
  ];

  // States
  const [displayDeleteDisclaimerModal, setDisplauDeleteDisclaimeeModal] =
    useState(false);
  const [displayDeletedModal, setDisplayDeletedModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setNotifications } = useApp();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await closeAccount();

      setDisplauDeleteDisclaimeeModal(false);
      setDisplayDeletedModal(true);
    } catch (error) {
      const errorMessage = errors.format(error);

      setDisplauDeleteDisclaimeeModal(false);
      setNotifications([
        { title: errorMessage, id: Date.now(), severity: "error" },
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ProfileSectionContainer
      header="Deactivate account"
      paragraph="Put your account on hold temporarily."
    >
      {displayDeleteDisclaimerModal && (
        <AcceptedModal
          onClick={() => {
            setDisplauDeleteDisclaimeeModal(false);
          }}
          body={
            <DeleteDisclaimerModalBody
              isSubmitting={isSubmitting}
              onClick={() => {
                setDisplauDeleteDisclaimeeModal(false);
              }}
              onClick2={handleSubmit}
            />
          }
        />
      )}
      {displayDeletedModal && (
        <AcceptedModal
          onClick={() => {
            setDisplayDeletedModal(false);
          }}
          body={
            <DeleteSuccessfulModalBody
              onClick={() => {
                setDisplayDeletedModal(false);
              }}
            />
          }
        />
      )}
      <div className={classes.container}>
        <h4>Warning</h4>
        <ul className="list-disc pl-10">
          {closeDisclaimers.map((data, i) => {
            return <li key={i}>{data}</li>;
          })}
        </ul>

        {/* <Button
          type="invalid"
          onClick={() => {
            setDisplauDeleteDisclaimeeModal(true)
          }}
        >
          Deactivate account
        </Button> */}
        <Button
          type="invalid"
          onClick={() => {
            setDisplauDeleteDisclaimeeModal(true);
          }}
        >
          Close account
        </Button>
      </div>
    </ProfileSectionContainer>
  );
};

export default ProfileAccountCloseAccount;
