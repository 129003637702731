const errors = {
  format(error: any) {
    const errorMessage =
      error?.response?.data?.error?.responseMessage ||
      error?.response?.data?.responseMessage?.toString() ||
      error?.message ||
      'There was an issue making this request'

    return errorMessage
  },
}

export default errors
