import Svgs from "Assets/Svgs";
import Button from "Components/Button/Button";
import { useNavigate } from "react-router-dom";

const ProfileCreated = () => {
  const navigate = useNavigate();

  return (
    <section>
      <div>
        <div className="h-[280px] w-[280px]"></div>

        <h2 className="text-[39px] text-center mb-2.5">Profile created</h2>

        <p className="text-center mb-10">
          Thank you for choosing iṣẹ́ School. Get ready for an amazing tech
          journey.
        </p>
      </div>

      <div>
        <Button
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          <span className="flex items-center gap-x-2.5">
            <span>Proceed to your dashboard</span>

            <Svgs.Arrow className="text-white" />
          </span>
        </Button>
      </div>
    </section>
  );
};

export default ProfileCreated;
