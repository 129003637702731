import emailChange from 'Assets/Images/emailChange.svg'
import Button from 'Components/Button/Button'
import { useAuth } from 'Context/AuthUserContext'

type TProps = {
  email: string
}

const ChangeEmailComformedModalBody = ({ email }: TProps) => {
  const { logout } = useAuth()

  return (
    <div className="min-w-[876px] pt-20 pb-16">
      <img src={emailChange} alt="Success" className="mx-auto" />

      <h2 className="text-center text-[25px] font-medium mb-3 mt-5">
        Email change confirmation
      </h2>

      <p className="w-[426px] mx-auto text-[#737373] text-center">
        We sent a confirmation link to your new email address ({email}). Please
        check your inbox and click the link to complete the process.
      </p>

      <div className="mx-auto w-[106px] mt-8">
        <Button onClick={logout}>Login</Button>
      </div>
    </div>
  )
}

export default ChangeEmailComformedModalBody
