export const UserOnboardingSvg1 = () => {
  return (
    <svg
      width="821"
      height="454"
      viewBox="0 0 821 454"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="621.003"
        height="392.634"
        transform="matrix(0.85934 -0.511405 0.510969 0.859599 86.6797 -70.6992)"
        fill="#664EFE"
      />
      <rect
        width="515.85"
        height="37.9863"
        transform="matrix(0.8659 -0.500216 0.499784 0.86615 247.695 200.966)"
        fill="#FDC500"
      />
      <rect
        width="439.714"
        height="37.9863"
        transform="matrix(0.8659 -0.500216 0.499784 0.86615 -133.047 420.815)"
        fill="#664EFE"
      />
    </svg>
  );
};

export const UserOnboardingSvg2 = () => {
  return (
    <svg
      width="460"
      height="461"
      viewBox="0 0 460 461"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="96.8527"
        cy="99.4946"
        rx="96.8527"
        ry="99.4946"
        transform="matrix(0.883288 -0.468831 0.487853 0.872926 42.9102 198.343)"
        fill="#FDC500"
      />
      <ellipse
        cx="96.8527"
        cy="99.4946"
        rx="96.8527"
        ry="99.4946"
        transform="matrix(0.883288 -0.468831 0.487853 0.872926 148.199 178.909)"
        fill="#664EFE"
      />
    </svg>
  );
};

export const UserOnboardingSvg3 = () => {
  return (
    <svg
      width="317"
      height="102"
      viewBox="0 0 317 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="144.979"
        width="290"
        height="130.925"
        transform="rotate(-29.995 0 144.979)"
        fill="#FDC500"
      />
    </svg>
  );
};

export const UserOnboardingSvg4 = () => {
  return (
    <svg
      width="204"
      height="259"
      viewBox="0 0 204 259"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="15.792"
        y="130.14"
        width="290"
        height="80"
        transform="rotate(-34.6796 15.792 130.14)"
        fill="#664EFE"
      />
      <rect
        x="59.4521"
        y="194.087"
        width="290"
        height="30"
        transform="rotate(-35.8642 59.4521 194.087)"
        fill="#FDC500"
      />
    </svg>
  );
};

export const UserOnboardingSvg5 = () => {
  return (
    <svg
      width="294"
      height="77"
      viewBox="0 0 294 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.46875"
        y="156.189"
        width="290"
        height="90"
        transform="rotate(-32.3793 0.46875 156.189)"
        fill="#664EFE"
      />
    </svg>
  );
};
