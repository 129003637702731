import classes from "./Header.module.css";
import userAvatar from "../../Assets/Images/userAvatar.avif";

import iseLogo from "../../Assets/Images/iseLogo.svg";
import { useContext, useEffect, useRef, useState } from "react";
import HeaderSideNav from "../HeaderSideNav/HeaderSideNav";
import { AuthUserContext } from "../../Context/AuthUserContext";
import NotificationsContainer from "../NotificationsContainer/NotificationsContainer";
import { useNavigate } from "react-router-dom";

type HeaderTypes = {
  closeSideNavProp: boolean | undefined;
};

const Header = ({ closeSideNavProp }: HeaderTypes) => {
  // Refs
  const sideNav = useRef<null | HTMLDivElement>(null);
  const notificationRef = useRef<null | HTMLDivElement>(null);

  // COntext
  const { user, badges, userBadgesObject } = useContext(AuthUserContext);

  // States
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([
    // {
    //   text: "Congratulations, 2 friends used your referral link. You have unlocked Trailblazer! ",
    //   from: "Referral",
    //   date: "4 days ago",
    //   isRead: true,
    // },
    // {
    //   text: "Congratulations, 2 friends used your referral link. You have unlocked Trailblazer! ",
    //   from: "Referral",
    //   date: "4 days ago",
    //   isRead: false,
    // },
    // {
    //   text: "Congratulations, 2 friends used your referral link. You have unlocked Trailblazer! ",
    //   from: "Referral",
    //   date: "4 days ago",
    //   isRead: false,
    // },
    // {
    //   text: "Congratulations, 2 friends used your referral link. You have unlocked Trailblazer! ",
    //   from: "Referral",
    //   date: "4 days ago",
    //   isRead: false,
    // },
    // {
    //   text: "Congratulations, 2 friends used your referral link. You have unlocked Trailblazer! ",
    //   from: "Referral",
    //   date: "4 days ago",
    //   isRead: false,
    // },
  ]);
  const [allIsRed, setAllIsRead] = useState(false);
  const [activeBadge, setActiveBadge] = useState<any[]>();

  // Utils
  const openSideNav = () => {
    if (sideNav.current) {
      sideNav.current.style.width = "100%";
    }
  };

  const closeSideNav = () => {
    if (sideNav.current) {
      sideNav.current.style.width = "0%";
    }
  };

  // Router
  const navigate = useNavigate();

  useEffect(() => {
    const removeDropdownHandler = (e: any) => {
      if (notificationRef && !notificationRef?.current?.contains(e.target)) {
        setShowNotification(false);
      } else {
      }
    };
    document.addEventListener("mousedown", removeDropdownHandler);

    return () => {
      document.removeEventListener("mousedown", removeDropdownHandler);
    };
  }, []);

  useEffect(() => {
    setAllIsRead(
      notifications.filter((data: any) => {
        return !data?.isRead;
      }).length === 0
    );
  }, [notifications]);

  useEffect(() => {
    if (userBadgesObject.data) {
      const activeBadgesCopy = badges.filter((data) => {
        return data.earned;
      });
      setActiveBadge(activeBadgesCopy[activeBadgesCopy?.length - 1]);
    }

    // eslint-disable-next-line
  }, [userBadgesObject.data]);

  return (
    <section className={classes.container}>
      <img
        src={iseLogo}
        alt="Ise"
        className={classes.logo}
        onClick={() => {
          navigate("/");
        }}
      />
      {!closeSideNavProp &&
        // <div className={classes.inputSection}>
        //   <input type="text" placeholder="Search" />
        //   <svg
        //     xmlns="http://www.w3.org/2000/svg"
        //     width="24"
        //     height="24"
        //     viewBox="0 0 24 24"
        //     fill="none"
        //   >
        //     <path
        //       d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
        //       stroke="#2E2E2E"
        //       strokeWidth="2"
        //       strokeLinecap="round"
        //       strokeLinejoin="round"
        //     />
        //   </svg>
        // </div>
        null}
      {closeSideNavProp && (
        <div>
          <img
            src={user?.data?.profile || iseLogo}
            alt="Ise"
            className={classes.logoHeader}
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      )}
      <div className={classes.userSection}>
        <div className={classes.notitication}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            onClick={() => {
              setShowNotification((prevState) => {
                return !prevState;
              });
            }}
          >
            <path
              d="M13.73 21C13.5542 21.3031 13.3018 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
              stroke="#101828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          {!allIsRed && <div className={classes.indicator}></div>}
          {showNotification && (
            <div
              className={classes.notificationsContainer}
              ref={notificationRef}
            >
              <NotificationsContainer
                notifications={notifications}
                setNotifications={setNotifications}
                allIsRed={allIsRed}
                setAllIsRead={setAllIsRead}
              />
            </div>
          )}
        </div>
        <div className={classes.activeBagge}>
          {(activeBadge as any)?.svg || (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <g opacity="0.38">
                <path
                  d="M9.49963 12.0006L11.4996 14.0006L15.4996 10.0006M8.33437 4.69766C9.05191 4.6404 9.73309 4.35824 10.281 3.89136C11.5594 2.80184 13.4398 2.80184 14.7183 3.89136C15.2662 4.35824 15.9474 4.6404 16.6649 4.69766C18.3393 4.83128 19.669 6.16092 19.8026 7.83535C19.8598 8.55288 20.142 9.23407 20.6089 9.78193C21.6984 11.0604 21.6984 12.9408 20.6089 14.2193C20.142 14.7672 19.8598 15.4483 19.8026 16.1659C19.669 17.8403 18.3393 19.1699 16.6649 19.3036C15.9474 19.3608 15.2662 19.643 14.7183 20.1099C13.4398 21.1994 11.5594 21.1994 10.281 20.1099C9.73309 19.643 9.05191 19.3608 8.33437 19.3036C6.65994 19.1699 5.3303 17.8403 5.19668 16.1659C5.13942 15.4483 4.85727 14.7672 4.39038 14.2193C3.30086 12.9408 3.30086 11.0604 4.39038 9.78193C4.85727 9.23407 5.13942 8.55288 5.19668 7.83535C5.3303 6.16092 6.65994 4.83128 8.33437 4.69766Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          )}
        </div>
        <p>
          {user?.data?.first_name} {user?.data?.last_name}
        </p>
        <img src={user?.data?.profile_image || userAvatar} alt="User" />
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        className={classes.sideNavIndicator}
        onClick={openSideNav}
      >
        <path
          d="M4 6.5H20M4 12.5H20M4 18.5H20"
          stroke="#2E2E2E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 6.5H20M4 12.5H20M4 18.5H20"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 6.5H20M4 12.5H20M4 18.5H20"
          stroke="black"
          strokeOpacity="0.2"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <div className={classes.sideNav} ref={sideNav}>
        <HeaderSideNav closeSideNav={closeSideNav} />
      </div>
    </section>
  );
};

export default Header;
