const Google = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8055 10.5415H21V10.5H12V14.5H17.6515C16.827 16.8285 14.6115 18.5 12 18.5C8.6865 18.5 6 15.8135 6 12.5C6 9.1865 8.6865 6.5 12 6.5C13.5295 6.5 14.921 7.077 15.9805 8.0195L18.809 5.191C17.023 3.5265 14.634 2.5 12 2.5C6.4775 2.5 2 6.9775 2 12.5C2 18.0225 6.4775 22.5 12 22.5C17.5225 22.5 22 18.0225 22 12.5C22 11.8295 21.931 11.175 21.8055 10.5415Z"
        fill="#737373"
      />
      <path
        d="M3.15308 7.8455L6.43858 10.255C7.32758 8.054 9.48058 6.5 12.0001 6.5C13.5296 6.5 14.9211 7.077 15.9806 8.0195L18.8091 5.191C17.0231 3.5265 14.6341 2.5 12.0001 2.5C8.15908 2.5 4.82808 4.6685 3.15308 7.8455Z"
        fill="#FF3D00"
      />
      <path
        d="M11.9999 22.4999C14.5829 22.4999 16.9299 21.5114 18.7044 19.9039L15.6094 17.2849C14.5717 18.074 13.3036 18.5009 11.9999 18.4999C9.39891 18.4999 7.19041 16.8414 6.35841 14.5269L3.09741 17.0394C4.75241 20.2779 8.11341 22.4999 11.9999 22.4999Z"
        fill="#4CAF50"
      />
      <path
        d="M21.8055 10.5415H21V10.5H12V14.5H17.6515C17.2571 15.6082 16.5467 16.5766 15.608 17.2855L15.6095 17.2845L18.7045 19.9035C18.4855 20.1025 22 17.5 22 12.5C22 11.8295 21.931 11.175 21.8055 10.5415Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default Google;
