import success from "Assets/Gifs/success.gif";
import Button from "Components/Button/Button";
import { useAuth } from "Context/AuthUserContext";

type DeleteDisclaimerModalBodyProps = {
  onClick: () => void;
};

const DeleteSuccessfulModalBody = ({
  onClick,
}: DeleteDisclaimerModalBodyProps) => {
  const { logout } = useAuth();

  return (
    <div className="w-[928px] pt-20">
      <img src={success} alt="Success" className="mx-auto" />

      <h4 className="text-center text-[25px] font-medium">Account deleted</h4>

      <p className="text-center max-w-[480px] mx-auto text-[#737373] mt-3.5 mb-9">
        Your account has been successfully closed. We appreciate your time with
        us and wish you all the best in your future endeavors.
      </p>

      <div className="w-[226px] mx-auto">
        <Button onClick={logout}>Return to homepage</Button>
      </div>
    </div>
  );
};

export default DeleteSuccessfulModalBody;
